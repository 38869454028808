const BASE_URL = process.env.VUE_APP_BASE_URL//"http://116.90.165.45:7080/db/bmkgsatu";
import axios from "axios";
import Helper from "@/api/helper";

export default {

  getListUser() {
    var url = BASE_URL + "/@listusers";
    // var url = BASE_URL + "@search?type_name=User"
    return axios.get(url, Helper.getConfig());
  },

  getDetailUser(path) {
    var url = BASE_URL + path;
    return axios.get(url, Helper.getConfig());
  },

  getListUserInfo() {
    var url = BASE_URL + "/@listuser_info";
    return axios.get(url, Helper.getConfig());
  },

  saveChanges(path, data) {
    var url = BASE_URL + path;
    return axios.patch(url, data, Helper.getConfig());
  },

  addNewUser(path, data) {
    var url = BASE_URL + path;
    return axios.post(url, data, Helper.getConfig());
  },

  deleteUser(path) {
    var url = BASE_URL + path;
    return axios.delete(url,Helper.getConfig());
  },

  assignUserStation(username, data) {
    var url = BASE_URL + "/users/" + username;
    return axios.patch(url, data, Helper.getConfig());
  },

  getObserverbyStationId(station_id) {
    var url = BASE_URL + "/@search?type_name=Observer&station_id="+station_id+"&_sort_asc=observer_name&_metadata=id,path,observer_nip,observer_name,observer_telephone,is_active";
  
    return axios.get(url, Helper.getConfig());
  },

  createLoginLogActivity(username) {
    var url = BASE_URL + "/logactivity";
    var d = new Date()
    let year = d.getFullYear()
    let month =  ("0" + (d.getMonth()+1)).slice(-2);
    let day = ("0" + (d.getDay()+1)).slice(-2);
    let hour = ("0" + d.getHours()).slice(-2);
    let minute = ("0" + d.getMinutes()).slice(-2);
    let second = ("0" + d.getSeconds()).slice(-2);
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let formatDate = d.toLocaleDateString("en-US", options)
    var data = { 
      "@type": "LogActivity",
      "id": "logactivity_"+year+"-"+month+"-"+day+"T"+hour+"_"+minute+"_"+second,
      "log_username": username,
      "log_activity": "Login",
      "log_module": "",
      "log_description": "Login pada tanggal "+formatDate
    }
    return axios.post(url, data, Helper.getConfig());
  },

  getListLogActivity(username) {
    var url = BASE_URL + "/@aggregation_query?type_name=LogActivity&log_username="+username+"&_metadata=id,log_username,log_activity,log_module,log_description";
    return axios.get(url, Helper.getConfig());
  },

  changePassword(data) {
    var url = BASE_URL + "/@changePassword";
    // var url = BASE_URL + "@search?type_name=User"
    return axios.patch(url, data, Helper.getConfig());
  },

};
