const BASE_URL = process.env.VUE_APP_BASE_URL// "http://116.90.165.45:7080/db/bmkgsatu";
import axios from "axios";
import Helper from "@/api/helper";

export default {

  getListAllRole() {
    // var url = BASE_URL + "/bmkgroleperm/@items";
    // var url = BASE_URL + "/bmkgroleperm/@aggregation_query?type_name=BmkgRole&_metadata=rolename,bmkgroleperm&_size=1000"
    var url = BASE_URL + "/bmkgroleperm/@aggregation_query?type_name=BmkgRole&_metadata=id,rolename&_size=1000"
    return axios.get(url, Helper.getConfig());
  },

  getDetailRole(path) {
    var url = BASE_URL + "/bmkgroleperm/" + path;
    return axios.get(url, Helper.getConfig());
  },

  addNewRole(data){
    var url = BASE_URL + "/bmkgroleperm";
    return axios.post(url, data, Helper.getConfig());
  },

  deleteRole(param) {
    var url = BASE_URL + "/bmkgroleperm/"+param;
    return axios.delete(url, Helper.getConfig());
  },

  addPermission(path, data) {
    var url = BASE_URL + "/bmkgroleperm/" + path;
    return axios.patch(url, data, Helper.getConfig());
  },

  deletePermission(path, data) {
    var url = BASE_URL + path;
    return axios.post(url, data, Helper.getConfig());
  },

  getListAllPermission() {
    var url = BASE_URL + "/@bmkgsatu_permissions";
    return axios.get(url, Helper.getConfig());
  },

  deleteUser(path) {
    var url = BASE_URL + path;
    return axios.delete(url,Helper.getConfig());
  },

  getListPermissionByRole(role) {
    var url = BASE_URL + "/bmkgroleperm/" + role;
    return axios.get(url, Helper.getConfig());
  },

  updateRolePermission(role, data) {
    var url = BASE_URL + "/bmkgroleperm/" + role;
    return axios.patch(url, data, Helper.getConfig());
  },

  

};
