<template>
	<div>
		<b-card title="Manage Users">
			<div class="custom-search d-flex justify-content-end">

				<div>
					<b-form-group>
						<div class="d-flex align-items-center">
						<label class="mr-1">Search</label>
						<b-form-input
							placeholder="Search"
							type="text"
							class="d-inline-block"
								@input="advanceSearch"
						/>
						</div>
					</b-form-group>
				</div>

				<div>
					<b-button
						class="ml-2"
						variant="gradient-primary float-right"
						size="md"
						v-b-modal.modal-add
					>
					Add User
					</b-button>
				</div>
			</div>

			<!-- table -->
			<vue-good-table
				:columns="columns"
				:rows="rows"
				:rtl="direction"
				:isLoading="tableLoading"
				:search-options="{
					enabled: true,
					externalQuery: searchTerm }"
				:pagination-options="{
					enabled: true,
					perPage:pageLength
				}"
				theme="my-theme"
				@on-row-click="onRowClick"
			>
			<template
				slot="table-row"
				slot-scope="props"
			>
				<div
					v-if="props.column.field === 'action'"
					class="text-nowrap"
				>
					<b-button-group v-if="props.row.id != 'admin1'">
						<b-button
							variant="gradient-danger"
							size="sm"
							@click="onActionDelete(props.row.id)"
						>
							<trash-icon size="1x" />
						</b-button>
						<b-button
							variant="gradient-primary"
							size="sm"
							@click="onActionEdit(props.row.id)"
						>
							<edit-2-icon size="1x" />
						</b-button>
					</b-button-group>
				</div>
			</template> 

			<!-- pagination -->
			<template
				slot="pagination-bottom"
				slot-scope="props"
			>
				<div class="d-flex justify-content-between flex-wrap">
				<div class="d-flex align-items-center mb-0 mt-1">
					<span class="text-nowrap">
					Showing 1 to
					</span>
					<b-form-select
					v-model="pageLength"
					:options="['5','10','15', '20']"
					class="mx-1"
					@input="(value)=>props.perPageChanged({currentPerPage:value})"
					/>
					<span class="text-nowrap"> of {{ props.total }} entries </span>
				</div>
				<div>
					<b-pagination
						:value="1"
						:total-rows="props.total"
						:per-page="pageLength"
						first-number
						last-number
						align="right"
						prev-class="prev-item"
						next-class="next-item"
						class="mt-1 mb-0"
						@input="(value)=>props.pageChanged({currentPage:value})"
					>
						<template #prev-text>
							<feather-icon
							icon="ChevronLeftIcon"
							size="18"
							/>
						</template>
						<template #next-text>
							<feather-icon
							icon="ChevronRightIcon"
							size="18"
							/>
						</template>
					</b-pagination>
				</div>
				</div>
			</template>
			</vue-good-table>

			<!-- <template #code>
			{{ codeAdvance }}
			</template> -->
		</b-card>

		<b-modal
			id="modal-add"
			ref="modal-add"
			title="Add User"
			button-size="sm"
			size="md"
			centered
			no-close-on-backdrop
		
		>
			<b-row>
				<b-col class="mb-1">
				<label>Username</label>
				<b-form-input
					size="sm"
					type="text"
					v-model="newUser.username"
				></b-form-input>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>Name</label>
				<b-form-input
					size="sm"
					type="text"
					v-model="newUser.name"
				></b-form-input>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>Email</label>
				<b-form-input
					size="sm"
					type="text"
					:state="validateState($v.newUser.email)"
					v-model="$v.newUser.email.$model"
				></b-form-input>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>Departement</label>
				<b-form-input
					size="sm"
					type="text"
					v-model="newUser.department"
				></b-form-input>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>Role</label>
				<b-form-select size="sm" v-model="newUser.role">
					<option disabled value="">Pilihan</option>
					<option v-for="(role, i) in roles" :key="i" :value="role">
					{{ role }}
					</option>
				</b-form-select>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>Start Date</label>
				<b-form-datepicker
					v-model="newUser.start_date"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"
					locale="en"
					size="sm"
					placeholder="Pilih Tanggal"
					readonly
				></b-form-datepicker>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>End Date</label>
				<b-form-datepicker
					v-model="newUser.end_date"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"
					locale="en"
					size="sm"
					placeholder="Pilih Tanggal"
				></b-form-datepicker>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>New Password</label>
				<b-form-input
					size="sm"
					type="password"
					:state="validateState($v.newUser.password)"
					v-model="$v.newUser.password.$model"
				></b-form-input>
				</b-col>
			</b-row>

			<b-row>
				<b-col class="mb-1">
				<label>New Password (again)</label>
				<b-form-input
					size="sm"
					type="password"
					:state="validateState($v.newUser.password_again)"
					v-model="$v.newUser.password_again.$model"
				></b-form-input>
				</b-col>
			</b-row>

			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="gradient-danger" @click="cancel()">
				Cancel
				</b-button>
				<b-button
					size="sm"
					variant="gradient-primary"
					@click="createNewUser();"
				>
				Add
				</b-button>
			</template>
			<b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
		</b-modal>
	</div>

	
</template>

<script>

import {
  BCard, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButtonGroup, BButton, BOverlay, BModal, BTable, BFormCheckboxGroup, 
  BFormCheckbox, BFormDatepicker
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Service from "@/api/userservice";
import RoleService from "@/api/rolepermissionservice";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength, email } from "vuelidate/lib/validators";

export default {
	components: {
		// BCardCode,
		BCard,
		VueGoodTable,
		BAvatar,
		BPagination,
		BFormGroup,
		BFormInput,
		BFormSelect,
		BRow,
		BCol,
		BButtonGroup,
		BButton,
		BOverlay,
		TrashIcon,
		Edit2Icon,
		BModal,
		BTable, 
		BFormCheckboxGroup, 
		BFormCheckbox, 
		BFormDatepicker,
		// eslint-disable-next-line vue/no-unused-components
		ToastificationContent,
		
	},
	data() {
		return {
			pageLength: 10,
			dir: false,
			columns: [
				{
					label: 'Username',
					field: 'id',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Fullname',
					field: 'fullname',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Email',
					field: 'email',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Role',
					field: 'roles',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Department',
					field: 'department',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Action',
					field: 'action',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
			],
			// fields: [
			// 			{ key: "id", label: "Username", sortable: true },
			// 			{ key: "fullname", label: "Nama", sortable: true },
			// 			{ key: "email", label: "Email", sortable: true },
			// 			{ key: "department", label: "Department", sortable: true },
			// 			{ key: "action", label: "Action" },
			// 		],
			roles: [],
			rows: [],
			newUser: {
				id: "",
				name: "",
				username: "",
				email: "",
				department: "",
				role: "",
				start_date: "",
				end_date: "",
				password: "",
				password_again: "",
				assigned_stations: [],
			},
			searchTerm: '',
			showLoading: false,
			tableLoading: false,
			items: [],
			stationsTable: {
				fields: ["checked", "id", "name"],
				items: [
				{
					id: 1,
					name: "Stasiun Meteorologi Maimum Saleh",
					checked: false,
				},
				{
					id: 2,
					name: "Stasiun Meteorologi Malikussaleh",
					checked: false,
				},
				{
					id: 3,
					name: "Stasiun Meteorologi Sultan Iskandar Muda",
					checked: false,
				},
				],
			},
		}
	},

	mixins: [validationMixin],

	validations: {
		newUser: {
			password: {
				required,
				minLength: minLength(6),
			},
			password_again: {
				sameAsPassword: sameAs("password"),
			},
			email: {
				required,
				email
			},
		},
	},

	computed: {
		direction() {
			if (store.state.appConfig.isRTL) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.dir = true
				return this.dir
			}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.dir = false
			return this.dir
		},
	},
	// created() {
	// 	this.$http.get('/good-table/advanced-search')
	// 	.then(res => { this.rows = res.data })
	// },
	mounted() {
			this.getListUser();
			this.getListAllRole();
			var today = new Date();
			var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
			// console.log("date : ",date)
			this.newUser.start_date = date;
		},
	methods: {
		validateState(item) {
			const { $dirty, $error } = item;
			return $dirty ? !$error : null;
		},

		advanceSearch(val) {
			this.searchTerm = val
		},

		onRowClick(params) {
			// this.$toast({
			// 	component: ToastificationContent,
			// 	props: {
			// 	title: `Hello user! You have clicked on row ${params.row.id}`,
			// 	icon: 'UserIcon',
			// 	variant: 'success',
			// 	},
			// })
		},

		getListUser() {
			this.tableLoading = true;
			try {
				Service.getListUser()
					.then((response) => {
						this.tableLoading = false;
						console.log("list-users: ", response.data);
						// this.rows = response.data;
						let datas = []
						for (let data of response.data) {
							let element = data
							let myrole = ""
							if (element && element.roles.length > 1) {
								myrole = element.roles[1]
								myrole = myrole.split('.')[1]

								element.roles = myrole
							}
							else {
								element.roles = ""
							}

							if (element.properties.hasOwnProperty('department')) {
								element['department'] = element.properties.department
							}
							else {
								element['department'] = ""
							}

							datas.push(element)
						}

						this.rows = datas
						// this.totalRows = this.items.length;
					})
					.catch((error) => {
						this.tableLoading = false;
						// console.log("Eee : ", error.response.status);
					});
			} catch (error) {
				// console.log("error: ", error);
				this.tableLoading = false;
			}
		},

		getListAllRole() {
			this.showLoading = true;
			try {
				RoleService.getListAllRole()
					.then((response) => {
						this.showLoading = false;
						// console.log("list-roles: ", response.data.items);

						for (var i = 0; i< response.data.length; i++) {
							let role_name = response.data[i]['rolename'].split('.')
							this.roles.push(role_name[1])
						}
					})
					.catch((error) => {
						this.showLoading = false;
						console.log("Eee : ", error.response.status);
					});
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
		},

		onActionEdit(id) {
			// this.$router.push({ name: "edit-user", params: { userId: id } });
			this.$router.push({ name: "detail", params: { userId: id } });
		},

		onActionDelete(id) {
			// this.items = this.items.filter((item) => item.id !== id);
			// this.totalRows = this.items.length;
			this.$swal({
				title: "Apakah Anda Yakin?",
				text: "Anda ingin menghapus user '" + id + "' ?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Delete",
				allowOutsideClick: false,
				allowEscapeKey: false,
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.deleteUser(id);
				} else if (result.dismiss === "cancel") {
					return;
				}
			});
		},

		clearNewUser() {
			this.newUser = {
				id: "",
				username: "",
				email: "",
				name: "",
				department: "",
				role: "",
				start_date: "",
				end_date: "",
				password: "",
				password_again: "",
				assigned_stations: [],
			};
		},

		addNewUser(newUser) {
			this.rows.push(newUser);
			this.clearNewUser();
			this.$refs['modal-add'].hide()
		},

		createNewUser() {
			this.$v.$touch();
			console.log('this.$v', this.$v)
      		if (this.$v.$invalid) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Data tidak valid. Periksa kembali inputan anda",
						icon: "BellIcon",
						variant: "danger",
					},
				});
				return;
			}
			else {
				let post_roles = ["guillotina.Member"]
				if(this.newUser.role != "") {
					post_roles.push('bmkgsatu.'+this.newUser.role)
				}
				let post_data = {
					"@type": "User",
					email: this.newUser.email,
					password: this.newUser.password,
					username: this.newUser.username,
					name: this.newUser.name,
					user_roles: post_roles//["guillotina.Member"],
				};

				if (this.newUser.department && this.newUser.department != "") {
					post_data['properties'] = {'department': this.newUser.department}
				}

				if (this.newUser.start_date && this.newUser.start_date != "" && this.newUser.end_date && this.newUser.end_date) {
					post_data['guillotina.behaviors.dublincore.IDublinCore'] = {'creation_date': this.newUser.start_date, 'expiration_date': this.newUser.end_date}
				}

				this.showLoading = true;
				try {
					Service.addNewUser("/users", post_data)
						.then((response) => {
							this.showLoading = false;
							console.log("new-users: ", response.data);

							let resData = response.data;
							let newUser = {
								"@name": resData["@name"],
								id: resData.id,
								fullname: resData.fullname,
								email: resData.email,
								roles: this.newUser.role,
								department: this.newUser.department
							};
							// this.rows.push(newUser);
							this.addNewUser(newUser)
							let position = "top-center";
							this.$toast(
								{
									component: ToastificationContent,
									props: {
									title: "Data telah tersimpan",
									icon: "BellIcon",
									variant: "success",
									},
								},
								{ position }
							);
							this.hideModal()
						})
						.catch((error) => {
							this.showLoading = false;
							console.log("Eee : ", error.response.status);
							if (error.response.status == 401) {
							this.$swal({
								title: "Unauthorized!",
								text: "Sesi anda telah habis. Logout dan silahkan login kembali",
								customClass: {
								confirmButton: "btn btn-primary",
								},
								buttonsStyling: false,
							});
							} else if (error.response.status == 409) {
							this.$swal({
								title: "Conflict!",
								text: "Username telah ada.",
								customClass: {
								confirmButton: "btn btn-primary",
								},
								buttonsStyling: false,
							});
							} else {
							this.$swal({
								title: "Something wrong!",
								text: "" + error.response.status,
								icon: "warning",
								customClass: {
								confirmButton: "btn btn-primary",
								},
								buttonsStyling: false,
							});
							}
						});
				} catch (error) {
					console.log("error: ", error);
					this.showLoading = false;
				}
			}
		},

		deleteUser(id) {
			try {
				Service.deleteUser("/@users/" + id)
					.then((response) => {
						this.showLoading = false;
						console.log("new-users: ", response.data);

						this.rows = this.rows.filter((item) => item.id !== id);
						// this.totalRows = this.items.length;

						let position = "top-center";
						this.$toast(
						{
							component: ToastificationContent,
							props: {
							title: "Data telah dihapus",
							icon: "BellIcon",
							variant: "success",
							},
						},
						{ position }
						);
					})
					.catch((error) => {
						this.showLoading = false;
						console.log("Eee : ", error.response.status);
						if (error.response.status == 401) {
						this.$swal({
							title: "Unauthorized!",
							text: "Sesi anda telah habis. Logout dan silahkan login kembali",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						} else if (error.response.status == 404) {
						this.$swal({
							title: "Not Found!",
							text: "User ini tidak ada.",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						} else {
						this.$swal({
							title: "Something wrong!",
							text: "" + error.response.status,
							icon: "warning",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						}
					});
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
		},
	},
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
input.form-control {
    border: 1px solid #6e6b7b !important;
}

.vgt-table thead th.theadc {
	background-color: #f7fafc !important;
}
</style>